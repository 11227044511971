import React from 'react';

export const pbAcademyLang = {
    appointments: {
        headline: (
            <>
                <strong>Suche Dir</strong>
                <i> Deine/n Termin/e aus</i>
            </>
        ),
        paragraph: (
            <>
                Wähle aus, an welchem Termin Du teilnehmen könntest. Solltest Du
                an allen Terminen Zeit haben, klicke alle an.
            </>
        ),
    },
    questions: {
        headline: 'Fragen und Antworten zum Apothekenalltag',
        paragraph: (
            <>
                Beantworte einige Fragen zum Pohl-Boskamp Sortiment und zur
                Produkt-Empfehlung in Deinem Apothekenalltag.
            </>
        ),
    },
    training: {
        headline: (
            <>
                <i>Dieses Training</i> <strong>würde Dir gut stehen!</strong>
            </>
        ),
        paragraph: (
            <>
                Absolviere das Training, um Dich für die Akademie bewerben zu
                können.
            </>
        ),
    },
    gallery: {
        headline: (
            <>
                <strong>Lass Dich</strong> <i>inspirieren</i>
            </>
        ),
        paragraph: <>Impressionen aus der Pohl-Boskamp Akademie</>,
    },
    finishedQuestions: {
        headline: 'Danke, dass Du unsere Fragen beantwortet hast.',
        paragraph: (
            <>
                Absolviere das Training weiter unten unter Schritt 2, um Dich
                für die Pohl-Boskamp Akademie bewerben zu können.
            </>
        ),
    },
    finishedTraining: {
        headline: 'Danke, dass Du das Training absolviert hast.',
        paragraph: (
            <>
                Beantworte uns noch ein paar Fragen, um Dich für die
                Pohl-Boskamp Akademie bewerben zu können.
            </>
        ),
    },
    header: {
        headline: <strong>Pohl-Boskamp Akademie</strong>,
        paragraph: (
            <>
                Bewirb Dich jetzt für zwei erlebnisreiche Tage in der
                Pohl-Boskamp Akademie in Schleswig-Holstein.
            </>
        ),
    },
    hasAttended: {
        headline: <strong>Schön, dass Du da warst!</strong>,
        paragraph: (
            <>
                Wir bedanken uns ganz herzlich für den tollen Tag und hoffen,
                auch Dir hat der Tag Spaß gemacht. Ein paar Eindrücke findest Du
                unten in der Gallerie.
            </>
        ),
    },
    unAttended: {
        headline: (
            <strong>
                Es erwarten Dich zwei spannende Tage mit Goldschmiedekurs und
                einer Betriebsführung bei Pohl-Boskamp <br /> (inkl. Anreise,
                Übernachtung und Verpflegung).
            </strong>
        ),
        paragraph: (
            <>
                Und so funktioniert´s: Beantworte 12 Fragen und absolviere das
                vorgeschlagene Training. Dann kannst Du Dich für einen Termin
                für die Pohl-Boskamp Akademie bewerben. Und mit ein bisschen
                Glück bist Du auch dabei.
                <br />
            </>
        ),
    },
    congratulations: {
        headline: (
            <>
                <strong>Vielen Dank! </strong>
                <br />
                Du hast alle Fragen beantwortet und das erforderliche Training
                absolviert. Nun bist Du bereit, um Dich auf einen Platz in der
                Pohl-Boskamp Akademie zu bewerben.
            </>
        ),
        paragraph: (
            <>
                Und so funktioniert's: Wähle den/die Termin/e aus, an denen Du
                teilnehmen könntest und klicke auf speichern. Mit etwas Glück
                erhältst Du eine Einladung zur Pohl-Boskamp Akademie.
            </>
        ),
    },
    noTraining: {
        headline: (
            <>
                Im Moment läuft leider keine Bewerbung für die Pohl-Boskamp
                Akademie!
            </>
        ),
        paragraph: (
            <>
                Doch keine Sorge: Sobald neue Termine verfügbar sind, findest Du
                sie in diesem Bereich.
            </>
        ),
    },
    modal: {
        success: {
            headline: 'Vielen Dank!',
            paragraph: (
                <>
                    Du hast Deine(n) Termin(e) ausgewählt und nimmst automatisch
                    an der Verlosung teil.
                    <br /> Mit ein bisschen Glück bist Du dabei.
                </>
            ),
        },
        canceled: {
            headline: 'Vielen Dank!',
            paragraph: (
                <>
                    Es ist kein Termin ausgewählt.
                    <br /> Du nimmst nicht an der Verlosung teil.
                </>
            ),
        },
    },
};
