import React from 'react';
import PropTypes from 'prop-types';
import {Column, Container, Row, Section} from '../../../layout/Grid';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import {ReactComponent as TrainingSVG} from '../../../../assets/images/personalisierung/img-personalisierung-abschluss.svg';

import ShopWindow from '../../../../assets/images/trainings/img-schaufenster--hintergrund-desktop.png';
import {pbAcademyLang} from '../../../../lang/pages/pb-academy';

const PBAcademyFinishedTraining = () => {
    return (
        <>
            <Container width="narrow">
                <Row>
                    <Column
                        md={12}
                        style={{
                            textAlign: 'center',
                            flexDirection: 'column',
                        }}>
                        <Headline type="h2">
                            {pbAcademyLang.finishedTraining.headline}
                        </Headline>
                        <Paragraph>
                            {pbAcademyLang.finishedTraining.paragraph}
                        </Paragraph>
                    </Column>
                </Row>
            </Container>
            <Section bgImage={ShopWindow}>
                <Row>
                    <Column
                        md={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                        <Container width="narrow">
                            <TrainingSVG />
                        </Container>
                    </Column>
                </Row>
            </Section>
        </>
    );
};

PBAcademyFinishedTraining.propTypes = {
    Training: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PBAcademyFinishedTraining;
