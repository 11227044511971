import React from 'react';
import PropTypes from 'prop-types';
import {Column, Container, Row} from '../../../layout/Grid';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import {ReactComponent as TrainingSVG} from '../../../../assets/images/personalisierung/img-personalisierung-abschluss.svg';
import {pbAcademyLang} from '../../../../lang/pages/pb-academy';

const PBAcademyFinishedQuestions = () => {
    return (
        <Row>
            <Column
                md={12}
                style={{
                    textAlign: 'center',
                    flexDirection: 'column',
                }}>
                <Headline type="h2">
                    {pbAcademyLang.finishedQuestions.headline}
                </Headline>
                <Paragraph>
                    {pbAcademyLang.finishedQuestions.paragraph}
                </Paragraph>
            </Column>
            <Column
                md={12}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <Container width="narrow">
                    <TrainingSVG />
                </Container>
            </Column>
        </Row>
    );
};

PBAcademyFinishedQuestions.propTypes = {
    Questions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PBAcademyFinishedQuestions;
