import styled from 'styled-components';
import {color} from '../../../styles/variables';
import {sm} from '../../../styles/media';

export const QuestionBoxWrapper = styled.div`
    display: flex;
    border: 2px solid ${color.mediumPetrol};
    background-color: ${color.white};
    padding: 3rem;
    ${sm`
        padding: 4rem;
    `}
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
`;

export const CheckboxWrapper = styled.span`
    position: absolute;
    right: 1rem;
    top: 1rem;
    .ap-custom-checkbox {
        margin-right: 0rem !important;
    }
`;
