import React from 'react';
import PropTypes from 'prop-types';

import Image from '../../../components/Image';
import {Column, Container, Row, Section} from '../../../layout/Grid';

const Gallery = ({images}) => {
    return (
        <Section>
            <Container width="medium">
                <Row>
                    <Column md={12}>
                        <Row>
                            {images?.map((image, index) => (
                                <Column key={index}>
                                    <Image
                                        height="200px"
                                        src={image}
                                        alt={`Vorschaubild-${index}`}
                                        responsive
                                    />
                                </Column>
                            ))}
                        </Row>
                    </Column>
                </Row>
            </Container>
        </Section>
    );
};

Gallery.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Gallery;
