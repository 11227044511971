import styled from 'styled-components';
import {color} from '../../../styles/variables';
import {sm} from '../../../styles/media';

export const DateBoxWrapper = styled.div`
    display: flex;
    gap: 1rem;
    border: 2px solid ${color.mediumPetrol};
    background-color: ${color.white};
    padding: 3rem;
    ${sm`
        padding: 3rem;
    `}
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
`;

export const CheckboxWrapper = styled.span`
    position: absolute;
    right: 1rem;
    top: 1rem;
    background-color: #7ad89b;
    border-radius: 50px;
    padding: 2.5px;
    .ap-custom-checkbox {
        margin-right: 0rem !important;
    }
`;
