import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import {Column, Container, Row, Section} from '../../layout/Grid';
import Button from '../../components/Button';
import Headline from '../../components/Headline';
import Paragraph from '../../components/Paragraph';
import Step from '../../components/Step';
import QuestionBox from '../../layout/QuestionBox';
import {CoreTextarea} from '../../components/Form';
import {
    confirmAnswers,
    selectAnswers,
    setAnswer,
} from '../../../model/pb-academy';
import {scrollToAnchorStart} from '../../../utils/scroll-utils';
import {questions} from '../../../lang/pb-academy-questions';
import {pbAcademyLang} from '../../../lang/pages/pb-academy';

const PBAcademyQuestions = () => {
    const [currentAnswer, setCurrentAnswer] = React.useState(null);
    const [combinedText, setCombinedText] = React.useState('');
    console.log('currentAnswer: ', currentAnswer);
    const textAreaRef = React.useRef(null);
    const dispatch = useDispatch();
    const allAnswers = useSelector(selectAnswers);
    const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
    const currentQuestion = questions[currentQuestionIndex];

    const handleNextQuestion = () => {
        // when combined question merge textarea value and currentAnswer before dispatch
        if (combinedText) {
            const answerArr = currentAnswer?.[currentQuestion.answerId] ?? [];
            dispatch(
                setAnswer({
                    [currentQuestion.answerId]: [...answerArr, combinedText],
                })
            );
        } else {
            dispatch(setAnswer(currentAnswer));
        }
        setCurrentAnswer(null);
        if (textAreaRef.current) {
            textAreaRef.current.value = '';
        }
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex((prevState) => prevState + 1);
        }
        if (currentQuestionIndex === questions.length - 1) {
            dispatch(confirmAnswers({...allAnswers, ...currentAnswer}));
        }
        scrollToAnchorStart('questions');
    };

    const stepText = currentQuestionIndex + 1;

    const handleChange = ({answer, answerId, isChecked = false}) => {
        if (currentQuestion.multipleAnswers) {
            let updatedAnswers = currentAnswer ? currentAnswer[answerId] : [];

            if (isChecked) {
                updatedAnswers = [...updatedAnswers, answer];
            } else {
                updatedAnswers = updatedAnswers.filter(
                    (item) => item !== answer
                );
            }
            if (updatedAnswers.length) {
                setCurrentAnswer({[answerId]: updatedAnswers});
            } else {
                setCurrentAnswer(null);
            }
        } else {
            setCurrentAnswer({[answerId]: [answer]});
        }
    };

    const isNextButtonEnabled =
        currentAnswer || combinedText !== '' ? true : false;

    return (
        <>
            <Section theme="blue">
                <Container width="medium">
                    <Row>
                        <Column
                            md={12}
                            style={{
                                textAlign: 'center',
                                flexDirection: 'column',
                            }}>
                            <Headline type="h2">
                                {pbAcademyLang.questions.headline}
                            </Headline>
                            <Paragraph>
                                {pbAcademyLang.questions.paragraph}
                            </Paragraph>
                        </Column>
                        <Column
                            md={12}
                            style={{
                                textAlign: 'center',
                                flexDirection: 'column',
                            }}>
                            <div data-scroll-anchor="questions">
                                <Step>
                                    {stepText} / {questions.length}
                                </Step>
                                <Paragraph fontWeight="bold">
                                    {stepText}. {currentQuestion.question}
                                </Paragraph>
                            </div>
                        </Column>
                    </Row>
                    <Row>
                        {currentQuestion.type === 'box' &&
                            currentQuestion.answers.map((answer) => (
                                <Column key={answer.id} md={6}>
                                    <QuestionBox
                                        answerId={currentQuestion.answerId}
                                        answer={answer}
                                        currentAnswer={currentAnswer}
                                        handleChange={handleChange}
                                    />
                                </Column>
                            ))}
                        {currentQuestion.type === 'text' && (
                            <Column md={12}>
                                <CoreTextarea
                                    label="Deine Nachricht"
                                    theme="default"
                                    ref={textAreaRef}
                                    onChange={(e) =>
                                        handleChange({
                                            answer: e.target.value,
                                            answerId: currentQuestion.answerId,
                                        })
                                    }
                                />
                            </Column>
                        )}
                        {currentQuestion.type === 'combined' && (
                            <>
                                {currentQuestion.answers.map((answer) => (
                                    <Column key={answer.id} md={6}>
                                        <QuestionBox
                                            answerId={currentQuestion.answerId}
                                            answer={answer}
                                            currentAnswer={currentAnswer}
                                            handleChange={handleChange}
                                        />
                                    </Column>
                                ))}
                                {currentQuestion.textAnswer && (
                                    <Column md={12}>
                                        <CoreTextarea
                                            label={currentQuestion.textAnswer}
                                            theme="default"
                                            ref={textAreaRef}
                                            onChange={(e) =>
                                                setCombinedText(e.target.value)
                                            }
                                        />
                                    </Column>
                                )}
                            </>
                        )}
                    </Row>
                    <Row>
                        <Column
                            md={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                            <Button
                                disabled={!isNextButtonEnabled}
                                label="Weiter"
                                rightIcon="triangleRight"
                                onClick={handleNextQuestion}
                            />
                        </Column>
                    </Row>
                </Container>
            </Section>
        </>
    );
};

PBAcademyQuestions.propTypes = {
    questions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PBAcademyQuestions;
