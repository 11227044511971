import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import PBAcademyQuestions from '../ui/domains/pb-academy';
import PBAcademyFinishedQuestions from '../ui/domains/pb-academy/Finished/FinishedQuestions';
import Layout from '../ui/layout/Layout';
import Header from '../ui/layout/Header';
import TrainingHeaderImage from '../assets/images/akademie/illu-pb_akademie.png';
import Paragraph from '../ui/components/Paragraph';
import Headline from '../ui/components/Headline';
import {Column, Container, Row, Section} from '../ui/layout/Grid';
import {loadAcademyDetails, selectAcademyDetails} from '../model/pb-academy';
import {selectById as selectTrainingsById} from '../model/training';
import PBAcademyFinishedTraining from '../ui/domains/pb-academy/Finished/FinishedTraining';
import PBAcademyAppointments from '../ui/domains/pb-academy/Appointments/Appointments';
import {questions} from '../lang/pb-academy-questions';
import {pbAcademyLang} from '../lang/pages/pb-academy';
import Gallery from '../ui/domains/pb-academy/Gallery';
import {ReactComponent as Buuble01} from '../assets/images/pb-academy/pb-academy-buuble-01.svg';
import {ReactComponent as Buuble02} from '../assets/images/pb-academy/pb-academy-buuble-02.svg';
import AcademyTraining from '../ui/domains/pb-academy/AcademyTraining';
import NoCampaign from '../assets/images/aktionen/img-keine-aktion.png';
import Image from '../ui/components/Image';

const PbAcademy = ({academyDetails, trainingsById}) => {
    const currentTraining = trainingsById[academyDetails?.training?.id];

    const isSurveyAndTrainingCompleted =
        academyDetails?.surveyCompleted && academyDetails?.trainingCompleted;

    return (
        <Layout>
            <Header img={TrainingHeaderImage} alt="" paddingTop>
                <Headline type="h1" fontWeight="bold">
                    {pbAcademyLang.header.headline}
                </Headline>
                <Paragraph>{pbAcademyLang.header.paragraph}</Paragraph>
            </Header>
            {!currentTraining && (
                <Section>
                    <Container width="narrow">
                        <Headline type="h3" align="center">
                            {pbAcademyLang.noTraining.headline}
                        </Headline>
                        <Paragraph size="small" align="center">
                            {pbAcademyLang.noTraining.paragraph}
                        </Paragraph>
                        <Image
                            src={NoCampaign}
                            alt={pbAcademyLang.noTraining.headline}
                        />
                    </Container>
                </Section>
            )}
            {currentTraining && (
                <>
                    <Section>
                        {academyDetails.hasAttended && (
                            <Container width="narrow">
                                <Headline
                                    type="h3"
                                    fontWeight="bold"
                                    align="center">
                                    {pbAcademyLang.hasAttended.headline}
                                </Headline>
                                <Paragraph size="small" align="center">
                                    {pbAcademyLang.hasAttended.paragraph}
                                </Paragraph>
                            </Container>
                        )}
                        {!academyDetails.hasAttended && (
                            <Container width="narrow">
                                <Headline type="h3" align="center">
                                    {isSurveyAndTrainingCompleted
                                        ? pbAcademyLang.congratulations.headline
                                        : pbAcademyLang.unAttended.headline}
                                </Headline>
                                <Paragraph size="small" align="center">
                                    {isSurveyAndTrainingCompleted
                                        ? pbAcademyLang.congratulations
                                              .paragraph
                                        : pbAcademyLang.unAttended.paragraph}
                                </Paragraph>
                            </Container>
                        )}
                    </Section>

                    {academyDetails.hasAttended && (
                        <Gallery images={academyDetails.photos} />
                    )}
                    {!academyDetails.hasAttended && (
                        <>
                            {isSurveyAndTrainingCompleted && (
                                <>
                                    {!academyDetails.hasAttended && (
                                        <PBAcademyAppointments
                                            appointments={academyDetails.dates}
                                        />
                                    )}
                                    <Column
                                        md={12}
                                        style={{
                                            textAlign: 'center',
                                            flexDirection: 'column',
                                        }}>
                                        <Headline type="h2">
                                            {pbAcademyLang.gallery.headline}
                                        </Headline>
                                        <Paragraph>
                                            {pbAcademyLang.gallery.paragraph}
                                        </Paragraph>
                                    </Column>
                                    <Gallery images={academyDetails.photos} />
                                </>
                            )}
                            {!isSurveyAndTrainingCompleted && (
                                <>
                                    <Section theme="blue">
                                        <Row>
                                            <Column
                                                md={12}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <Buuble01 />
                                            </Column>
                                        </Row>
                                        {academyDetails.surveyCompleted ? (
                                            <PBAcademyFinishedQuestions />
                                        ) : (
                                            <PBAcademyQuestions
                                                questions={questions}
                                            />
                                        )}
                                    </Section>
                                    <Section>
                                        <Row>
                                            <Column
                                                md={12}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <Buuble02 />
                                            </Column>
                                        </Row>
                                        {academyDetails.trainingCompleted ? (
                                            <PBAcademyFinishedTraining />
                                        ) : (
                                            <AcademyTraining
                                                currentTraining={
                                                    currentTraining
                                                }
                                            />
                                        )}
                                    </Section>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </Layout>
    );
};

const ConnectedPbAcademy = () => {
    const dispatch = useDispatch();
    const academyDetails = useSelector(selectAcademyDetails);
    const trainingsById = useSelector(selectTrainingsById);

    React.useEffect(() => {
        dispatch(loadAcademyDetails());
    }, [dispatch]);

    return (
        <PbAcademy
            academyDetails={academyDetails}
            trainingsById={trainingsById}
        />
    );
};

PbAcademy.propTypes = {
    academyDetails: PropTypes.object.isRequired,
    trainingsById: PropTypes.object.isRequired,
};

export default ConnectedPbAcademy;
