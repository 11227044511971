import React, {memo} from 'react';
import PropTypes from 'prop-types';

import {CheckboxWrapper, DateBoxWrapper} from './styles';
import Paragraph from '../../components/Paragraph';
import {Select} from '../../components/Form/Select';
import Icon from '../../components/Icon';
import {color} from '../../../styles/variables';
import {Column, Row} from '../Grid';
import {selectDates} from '../../../model/pb-academy';
import {useSelector} from 'react-redux';
const options = [
    {value: true, label: 'Ich habe Zeit'},
    {value: false, label: 'Ich kann leider nicht'},
];

const DateBox = ({
    startDate,
    endDate,
    id,
    isUserBooking,
    handleSelectedAppoinments,
}) => {
    const dates = useSelector(selectDates);
    const onChange = (selectedValues) => {
        handleSelectedAppoinments({id, userBooking: selectedValues.value});
    };

    const isSelected = dates.some((x) => x === id);

    return (
        <DateBoxWrapper>
            <Row>
                <Column md={12}>
                    <Paragraph margin="0px">
                        {startDate} & {endDate}
                    </Paragraph>
                </Column>
                <Column md={12}>
                    <Select
                        required={true}
                        name={`dates-${id}`}
                        placeholder="Teilnahme auswählen"
                        options={options}
                        onChange={onChange}
                        errors={{}}
                        defaultValue={isUserBooking ? options[0] : options[1]}
                    />
                </Column>
            </Row>
            {isSelected && (
                <CheckboxWrapper>
                    <Icon name="checkmark" color={color.white} />
                </CheckboxWrapper>
            )}
        </DateBoxWrapper>
    );
};

DateBox.propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    handleSelectedAppoinments: PropTypes.func.isRequired,
    isUserBooking: PropTypes.bool.isRequired,
};

export default memo(DateBox);
