import React from 'react';

export const questions = [
    {
        id: 1,
        answerId: 'answer_1',
        type: 'box',
        question: 'Welche Produkte aus der Gelo®-Markenwelt kennst Du?',
        answers: [
            'GeloMyrtol® forte',
            'Myrtol®',
            'GeloRevoice®',
            'GeloProsed®',
            'GeloTonsil®',
            'GeloSitin®',
            'GeloMuc®',
            'GeloBronchial® Saft',
            'GeloVital®',
            'Keins der genannten',
        ],
        multipleAnswers: true,
    },
    {
        id: 2,
        answerId: 'answer_2',
        type: 'box',
        question:
            'Welche der genannten Produkte empfiehlst Du Deinen Kundinnen und Kunden regelmäßig?',
        answers: [
            'GeloMuc®',
            'GeloProsed®',
            'GeloTonsil®',
            'Myrtol®',
            'GeloVital®',
            'GeloBronchial® Saft',
            'GeloSitin®',
            'GeloRevoice®',
            'GeloMyrtol® forte',
            'Keins der genannten',
        ],
        multipleAnswers: true,
    },
    {
        id: 3,
        answerId: 'answer_3',
        type: 'text',
        question: 'Was verbindest Du mit der Marke Gelo®?',
    },
    {
        id: 4,
        answerId: 'answer_4',
        type: 'text',
        question:
            'Wir möchten Dich bitten, kreativ zu werden: Welcher Werbeslogan für die Gelo®-Marken fällt Dir spontan ein?',
    },
    {
        id: 5,
        answerId: 'answer_5',
        type: 'box',
        question: 'Wie oft empfiehlst Du Produkte, die Mannose enthalten?',
        answers: ['Häufig', 'Gelegentlich', 'Selten', 'Nie'],
        multipleAnswers: false,
    },
    {
        id: 6,
        answerId: 'answer_6',
        type: 'box',
        question: 'Wem empfiehlst Du am häufigsten Mannose-Produkte?',
        answers: [
            'Frauen in oder nach den Wechseljahren',
            'Jungen Frauen von 18-30 Jahren',
            'Frauen im Alter von 30-45 Jahren',
            'Ich empfehle selten/keine Mannose-Produkte',
        ],
        multipleAnswers: false,
    },
    {
        id: 7,
        answerId: 'answer_7',
        type: 'combined',
        question:
            'Welche Produkte empfiehlst Du bei bakterieller Blasenentzündung gern?',
        answers: [
            'Produkte, die Mannose enthalten',
            'Produkte, die Cranberry enthalten',
            'Produkte, die Kapuzinerkresse enthalten',
            'Produkte, die Bärentraubenblätter enthalten',
            'Produkte, die Tausendgüldenkraut enthalten',
            'Produkte, die Goldrutenkraut enthalten',
        ],
        textAnswer: 'Andere Produkte: ',
        multipleAnswers: true,
    },
    {
        id: 8,
        answerId: 'answer_8',
        type: 'box',
        question: 'In welchen Fällen empfiehlst Du Imtimpflegeprodukte?',
        answers: [
            'Bei trockener Haut im äußeren Intimbereich',
            'Bei wiederkehrenden Entzündungen und Reizungen im äußeren Intimbereich',
            'Bei bakteriellen Dysbalancen im äußeren Intimbereich, hervorgerufen durch schwankende Hormonspiegel',
            'Zur Unterstützung der Keimprävention',
            'Ich empfehle keine Intimpflegeprodukte.',
        ],
        multipleAnswers: true,
    },
    {
        id: 9,
        answerId: 'answer_9',
        type: 'box',
        question: (
            <>
                NYDA® Läusespray mit nur 10 Minuten Einwirkzeit ist seit Februar
                2023 offizielles Nachfolgeprodukt des außer Vertrieb gemeldeten
                Produktes NYDA®. NYDA® express mit nur 10 Minuten Einwirkzeit
                ergänzt das NYDA®-Läusemittelsortiment bereits seit 2016.
                <br /> Worin unterscheiden sich NYDA® Läusespray und NYDA®
                express?
            </>
        ),
        answers: [
            'NYDA® Läusespray gibt es auch in der praktischen Doppelpackung, NYDA® express nicht.',
            'Beiden Produkten liegt zwar ein Läusekamm bei, aber nur NYDA® Läusespray darf während der Schwangerschaft und Stillzeit ohne Bedenken angewendet werden.',
            'NYDA® Läusespray kann für Kinder bis 12 Jahre auf dem roten Rezept verordnet werden, während NYDA® express in der Apotheke selbst gezahlt werden muss. Weiterhin erfolgt bei NYDA® Läusespray eine Wiederholungsbehandlung nach 8-10 Tagen, während NYDA® express nur einmal angewendet werden muss (eine Nachkontrolle nach 8-10 Tagen wird empfohlen).',
        ],
        multipleAnswers: false,
    },
    {
        id: 10,
        answerId: 'answer_10',
        type: 'text',
        question:
            'Gibt es in Deiner Apotheke viele Schwangere, die über Sodbrennen klagen? Falls ja, lassen sie sich in der Regel von Dir hinsichtlich einer Lösung für das Problem beraten oder kommen sie eher mit einem bestimmten Produktwunsch?',
    },
    {
        id: 11,
        answerId: 'answer_11',
        type: 'text',
        question:
            'Welches Produkt wird in Deiner Apotheke bei Sodbrennen in der Schwangerschaft in der Regel abgegeben?',
    },
    {
        id: 12,
        answerId: 'answer_12',
        type: 'text',
        question:
            'Welche Themen wünschst Du Dir als Lernmodul auf der Apotique?',
    },
];
