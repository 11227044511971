import React from 'react';
import PropTypes from 'prop-types';

import {Column, Container} from '../../../layout/Grid';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import {pbAcademyLang} from '../../../../lang/pages/pb-academy';
import ShopWindow from '../../../layout/ShopWindow';
import TrainingTeaser from '../../training/Teaser';
import {routes} from '../../../../model/navigation';

const AcademyTraining = ({currentTraining}) => {
    return (
        <>
            <Container width="narrow">
                <Column
                    md={12}
                    style={{
                        textAlign: 'center',
                        flexDirection: 'column',
                    }}>
                    <Headline type="h2">
                        {pbAcademyLang.training.headline}
                    </Headline>
                    <Paragraph>{pbAcademyLang.training.paragraph}</Paragraph>
                </Column>
            </Container>
            {currentTraining && (
                <ShopWindow teasers={[currentTraining]}>
                    {(training) => (
                        <TrainingTeaser
                            title={training.title}
                            type={training.type.name}
                            image={training.image}
                            points={training.points}
                            duration={training.duration}
                            completed={training.completed}
                            route={`${training.route}?${routes.academy}`}
                            isReleased={training.isReleased}
                        />
                    )}
                </ShopWindow>
            )}
        </>
    );
};

AcademyTraining.propTypes = {
    currentTraining: PropTypes.object.isRequired,
};

export default AcademyTraining;
