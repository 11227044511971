import React from 'react';
import PropTypes from 'prop-types';

import {CheckboxWrapper, QuestionBoxWrapper} from './styles';
import Paragraph from '../../components/Paragraph';
import {Checkbox} from '../../components/Form/Checkbox';

const QuestionBox = ({answer, answerId, handleChange, currentAnswer}) => {
    const handleOnChange = (event) => {
        handleChange({answer, answerId, isChecked: event.target.checked});
    };

    const isChecked = currentAnswer
        ? currentAnswer[answerId]?.some((item) => item === answer)
        : false;

    return (
        <QuestionBoxWrapper>
            <Paragraph margin="0px">{answer}</Paragraph>
            <CheckboxWrapper>
                <Checkbox
                    name={answer}
                    value={isChecked}
                    errors={{}}
                    onChange={handleOnChange}
                />
            </CheckboxWrapper>
        </QuestionBoxWrapper>
    );
};

QuestionBox.propTypes = {
    answer: PropTypes.string.isRequired,
    answerId: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    currentAnswer: PropTypes.object.isRequired,
};

export default QuestionBox;
