import React from 'react';
import PropTypes from 'prop-types';
import {navigate} from 'gatsby';

import {Column, Container, Row, Section} from '../../../layout/Grid';
import Button from '../../../components/Button';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import DateBox from '../../../layout/DateBox';
import {useDispatch, useSelector} from 'react-redux';
import {
    setDatesBooking,
    selectDates,
    confirmDatesBooking,
} from '../../../../model/pb-academy';
import {pbAcademyLang} from '../../../../lang/pages/pb-academy';
import trainingsLang from '../../../../lang/pages/trainings.lang';
import Lightbox from '../../../components/Lightbox';
import {ReactComponent as StarsSvg} from '../../../../assets/images/trainings/img-overlay--punkte.svg';
import {routes} from '../../../../model/navigation';

const PBAcademyAppointments = ({appointments}) => {
    const dispatch = useDispatch();
    const dates = useSelector(selectDates);
    const [isOpenModal, setIsOpenModal] = React.useState(false);

    const handleSetDatesBooking = () => {
        dispatch(confirmDatesBooking(dates));
        setIsOpenModal(true);
    };

    const handleSelectedAppoinments = (appointment) => {
        const {id, userBooking} = appointment;
        if (userBooking) {
            dispatch(setDatesBooking([...dates, id]));
        }
        if (!userBooking) {
            dispatch(setDatesBooking([...dates.filter((item) => item !== id)]));
        }
    };

    return (
        <>
            <Section theme="blue">
                <Container width="medium">
                    <Row>
                        <Column
                            md={12}
                            style={{
                                textAlign: 'center',
                                flexDirection: 'column',
                            }}>
                            <Headline type="h2">
                                {pbAcademyLang.appointments.headline}
                            </Headline>
                            <Paragraph>
                                {pbAcademyLang.appointments.paragraph}
                            </Paragraph>
                        </Column>
                    </Row>
                    <Row>
                        {appointments &&
                            appointments.map((appointment) => (
                                <Column key={appointment.id} xs={12} lg={6}>
                                    <DateBox
                                        id={appointment.id}
                                        text={appointment.date}
                                        startDate={appointment.startDate}
                                        endDate={appointment.endDate}
                                        isUserBooking={appointment.userBooking}
                                        handleSelectedAppoinments={
                                            handleSelectedAppoinments
                                        }
                                    />
                                </Column>
                            ))}
                    </Row>
                    <Row>
                        <Column
                            md={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                            <Button
                                label="Speichern"
                                rightIcon="triangleRight"
                                type="submit"
                                onClick={handleSetDatesBooking}
                            />
                        </Column>
                    </Row>
                </Container>
            </Section>
            <Lightbox
                isOpen={isOpenModal}
                onClose={() => setIsOpenModal(false)}>
                <Section>
                    <Container width="narrow" className="text-center">
                        <StarsSvg />
                        {dates.length > 0 && (
                            <>
                                <Headline fontWeight="bold" type="h5">
                                    {pbAcademyLang.modal.success.headline}
                                </Headline>
                                <Paragraph size="small">
                                    {pbAcademyLang.modal.success.paragraph}
                                </Paragraph>
                            </>
                        )}
                        {dates.length === 0 && (
                            <>
                                <Headline fontWeight="bold" type="h5">
                                    {pbAcademyLang.modal.canceled.headline}
                                </Headline>
                                <Paragraph size="small">
                                    {pbAcademyLang.modal.canceled.paragraph}
                                </Paragraph>
                            </>
                        )}
                        <Column xs={12}>
                            <Button
                                label={trainingsLang.successLightboxButton}
                                onClick={() => navigate(routes.trainings)}
                            />
                        </Column>
                    </Container>
                </Section>
            </Lightbox>
        </>
    );
};

PBAcademyAppointments.propTypes = {
    appointments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PBAcademyAppointments;
