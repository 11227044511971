import {createSelector} from 'reselect';

export const selectDomain = (state) => state.pbAcademy;

export const selectAnswers = createSelector(
    selectDomain,
    (domain) => domain.answers
);
export const selectAcademyDetails = createSelector(
    selectDomain,
    (domain) => domain.academyDetails
);
export const selectDates = createSelector(
    selectDomain,
    (domain) => domain.dates
);
